<template>
	<div class="container">
		<header-view ref="header" :class="{'on': minBar}" :showMenu="showMenu" :page="1" @change="changeMenu"></header-view>
		<right-tool :class="{'show': btnFlag}"></right-tool>
		<article class="wrapper">
			<div class="top">
				<div id="banner" class="banner flex-row">
					<div class="pic">
						<img class="animated picscall" src="../../assets/images/news_banner.jpg" >
					</div>
					<div class="ind_hint_wrap flex-col">
						<div class="ind_hint_icon animate__animated animate__fadeInUp" style="animation-delay: .2s;"><div></div></div>
						<div class="ind_hint ft-12 animate__animated animate__fadeInUp" style="animation-delay: .4s;">SCROLL DOWN</div>
					</div>
					<div class="topic">
						<div class="txt_ flex-col">
							<h1 class="ft-64 animate__animated animate__fadeInDown" style="animation-delay: .2s;">
								<span>
									<em class="animated fadeInDownSmall" style="animation-delay: 0s;">在</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="animation-delay: 0.1s;">这</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="animation-delay: 0.2s;">里</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="animation-delay: 0.3s;">发</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="animation-delay: 0.4s;">现</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="animation-delay: 0.5s;">世</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="animation-delay: 0.6s;">界</em>
								</span>
							</h1>
							<small class="ft-20 animate__animated animate__fadeInUp" style="animation-delay: .6s;">
								<span>
									<em class="animate__animated animate__fadeInUp" style="animation-delay: 0s;">Find</em>
								</span>
								<span>
									<em class="animate__animated animate__fadeInUp" style="animation-delay: 0.2s;">the</em>
								</span>
								<span>
									<em class="animate__animated animate__fadeInUp" style="animation-delay: 0.4s;">world</em>
								</span>
								<span>
									<em class="animate__animated animate__fadeInUp" style="animation-delay: 0.6s;">here</em>
								</span>
							</small>
						</div>
					</div>
				</div>
			</div>
			<div id="main" ref="main">
				<div id="main-wrap" class="main-wrap" ref="mainWrap">
					<div class="classify-wrap flex-row" v-if="typeList.length">
						<p class="flex-row animate__animated ft-16">
							<span @click="typeTap(0)" :class="{'active': typeIndex === 0}">全部</span>
							<em>/</em>
							<span @click="typeTap(1)" :class="{'active': typeIndex === 1}">{{typeList[0].text}}</span>
							<em>/</em>
							<span @click="typeTap(2)" :class="{'active': typeIndex === 2}">{{typeList[1].text}}</span>
						</p>
					</div>
					<div class="news-wrap flex-row" v-if="newsList.length">
						<div class="news-item animate__animated" v-for="(item,index) in newsList" :key="index" @click="newsDetail(item)">
							<div class="news-img">
								<img class="img" :src="item.coverUrl" />
							</div>
							<div class="news-content flex-col">
								<h2 class="title ellipsis ft-16">{{item.title}}</h2>
								<p class="detail-txt two-ellipsis ft-14">{{item.viceTitle}}</p>
								<!-- <p class="news-time ft-14" v-if="item.updateTime">{{item.updateTime.substring(0,10)}}</p> -->
								<p class="news-time ft-14">{{item.createTime.substring(0,10)}}</p>
							</div>
						</div>
					</div>
					<div class="empty-wrap flex-row" v-if="!newsList.length">
						<img v-if="!newsList.length && !isLoading" src="../../assets/images/empty_tabel.png" />
					</div>
				</div>
			</div>
		</article>
	</div>
</template>

<script>
	import headerView from '@/components/header/headerView.vue'
	import rightTool from '@/components/rightTool/index.vue'
	import { newsList,newsType } from '@/api/api.js'
	// TweenMax
	import gsap from 'gsap'
	import { TweenMax,Power2 } from 'gsap'
	gsap.registerPlugin(TweenMax)
	gsap.registerPlugin(Power2)
	import $ from 'jquery'
	export default{
		name: 'news',
		components: {
			headerView,
			rightTool
		},
		data() {
			return {
				showMenu: false,
				minBar: false,
				scTop: 0,
				func: null,
				typeIndex: 0,
				btnFlag: false,
				pageSize: 6,
				pageNo: 1,
				isLoading: false,
				isLoadMore: false,
				typeList: [],
				newsList: [],
				type: '',
			}
		},
		beforeRouteLeave(to, from, next) {
			if (to.path === '/newsDetail') {
				if (!this.$store.state.user.cacheList.includes('news')) {
					this.$store.state.user.cacheList.push('news')
				}
			}
			next();
		},
		created() {
			// this.getNewsTypeList()
		},
		mounted() {
			window.addEventListener("beforeunload", (function(){window.scrollTo(0,0);}));
			this.getNewsTypeList()
		},
		// 在离开页面时候会调用keep-alive钩子函数把数据保存到本地
		deactivated () {
			
		},
		// keepalive缓存了数据 当再次进入这样页面时候会走activated 构造函数
		activated () {
			document.body.style.cssText =`height:${$(window).height() + $('.main-wrap')[0].offsetHeight}px;background-color: #111111;`
			this.$refs.mainWrap.scrollTop = localStorage.getItem('scTop')
		},
		beforeDestroy() {
			window.removeEventListener('scroll',this.func)
		},
		destroyed() {
			document.body.style.cssText = "height: 100%;"
		},
		methods: {
			changeMenu(bool) {
				this.showMenu = !bool
			},
			// 节流
			throttle(func, wait, mustRun) {
				var timeout,
					startTime = new Date();
				return function() {
					var context = this,
						args = arguments,
						curTime = new Date();
					clearTimeout(timeout);
					// 如果达到了规定的触发时间间隔，触发 handler
					if(curTime - startTime >= mustRun){
						func.apply(context,args);
						startTime = curTime;
					// 没达到触发间隔，重新设定定时器
					}else{
						timeout = setTimeout(func, wait);
					}
				};
			},
			// 防抖
			debounce(func, wait) {
				let timeout;
				return function () {
					let context = this;
					let args = arguments;
					if (timeout) clearTimeout(timeout);
					timeout = setTimeout(() => {
						timeout = undefined
						func.apply(context, args)
					}, wait || 0);
				}
			},
			handleScroll(){
				$('body').addClass('disable-hover')
				this.throttle(()=>{
					$('body').removeClass('disable-hover')
				}, 300, 1000)()
				this.fn()
			},
			fn() {
				var scroH = $(document).scrollTop();  //滚动高度
				var viewH = $(window).height();  //可见高度
				var contentH = $(document).height();  //内容高度
				if(scroH > 650){  //距离顶部大于100px时
					this.minBar = true
					this.btnFlag = true
				}
				if (scroH < 500) {
					this.minBar = false
					this.btnFlag = false
				}
				if (contentH - (scroH + viewH) <= 100){  //距离底部高度小于100px
					// console.log('距离底部高度小于100px')
					if (!this.isLoadMore) {
						this.isLoadMore = true
						this.getList()
					}
				} 
				if (contentH == (scroH + viewH)){  //滚动条滑到底部啦
					// console.log('滚动条滑到底部啦')
				}
				this.scTop = $(document).scrollTop()
				TweenMax.to('#banner', .7, {x:0, y:-scroH * 0.3, z:0,ease:Power2.easeOut,force3D:true});
				TweenMax.to('#main', .7, {width: '100%',position:'fixed',x:0, y:-scroH, z:0,ease:Power2.easeOut,force3D:true});
				if (!$('.classify-wrap p').hasClass('animate__fadeInUp')) {
					this.setAnimate('.classify-wrap p','animate__fadeInUp')
				}
				this.setAnimate('.news-item','animate__fadeInUp')
			},
			setAnimate(dom,animateName) {
				if ($(dom).length === 1) {
					if ($(dom).hasClass('animate__fadeInUp')) {
						return
					}
					let domHeight = $(window).height() - ($(dom).offset().top - $(document).scrollTop()) - $(dom).height()
					if (($(window).height() - domHeight) > $(window).height() * 0.5) {
						$(dom).css("visibility","visible");
						$(dom).addClass(animateName)
					}					
				} else {
					var domList = $(dom)
					let domHeight = 0
					for(let i = 0; i < domList.length; i++) {
						if ($(domList[i]).hasClass('animate__fadeInUp')) {
							continue
						} else {
							domHeight = $(window).height() - ($(domList[i]).offset().top - $(document).scrollTop()) - $(domList[i]).height()
							if (($(window).height() + domHeight) > $(window).height() * 0.4) {
								if ((i+1)%3 === 0) {
									$(domList[i]).css({"visibility":"visible","animation-delay": "0.2s"});
								}
								if ((i+1)%3 === 1) {
									$(domList[i]).css({"visibility":"visible"});
								}
								if ((i+1)%3 === 2) {
									$(domList[i]).css({"visibility":"visible","animation-delay": "0.1s"});
								}
								$(domList[i]).addClass(animateName)
							}
						}
					}
				}
			},
			typeTap(val) {
				this.typeIndex = val
				this.clearData()
				switch (val) {
					case 0:
						this.type = ''
						this.getList();
					break;
					case 1:
						this.type = this.typeList[0].value
						this.getList();
					break;
					case 2:
						this.type = this.typeList[1].value
						this.getList();
					break;
				}
			},
			clearData() {
				this.pageNo = 1
				this.newsList = []
				this.isLoadMore = false
			},
			newsDetail(item) {
				localStorage.setItem('scTop', this.scTop)
				this.$router.push({path:'/newsDetail',query:{id:item.id}})
			},
			// 获取新闻类型
			getNewsTypeList() {
				newsType({isLogin: this.$store.state.user.isLogin ? 1 : 0}).then(res =>{
					if (res.success) {
						this.typeList = res.result
						this.getList()
					}
				})
			},
			// 获取新闻列表
			getList() {
				let data = {
					pageNo: this.pageNo,
					pageSize: this.pageSize,
					type: this.type,
					isLogin: this.$store.state.user.isLogin ? 1 : 0
				}
				this.isLoading = true
				newsList(data).then(res =>{
					if (res.success) {
						this.newsList = this.newsList.concat(res.result.records)
						if (res.result.records.length < this.pageSize) {
							this.isLoadMore = true
						} else {
							this.pageNo++
							this.isLoadMore = false
						}
					}  else {
						this.isLoadMore = true
					}
					this.isLoading = false
					setTimeout(() => {
						// 数据加载完重新计算高度
						document.body.style.cssText =`height:${$(window).height() + $('.main-wrap')[0].offsetHeight}px;background-color: #111111;`
						if (this.$route.query.scrollMain) {
							document.querySelector("#main-wrap").scrollIntoView(true)		
						}
						this.fn()
					},100)
					if (this.pageNo === 2) {
						this.func = this.throttle(this.handleScroll,1,500)
						window.addEventListener('scroll',this.func)
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	body{
		background-color: #111111;
	}
	@keyframes fn1 {
		0% {
			opacity: 0;
			clip-path: inset(100% 0 0 0);
			transform: translate3d(0%, 20px, 0);
		}
		100% {
			opacity: 1;
			clip-path: inset(0 0 0 0);
			transform: none;
		}
	}
	.fn1 {
		animation-name: fn1;
		animation-duration: 1.2s;
		animation-timing-function: cubic-bezier(0.49, 0.54, 0.16, 1);
	}
	.container{
		nav.on{
			padding: 0.5% 2%;
			background: rgba(0,0,0,.6);
			box-shadow: 0 7px 32px -12px rgba(0,0,0,.9);
		}
		.wrapper{
			.top{
				position: fixed;
				width: 100%;
				overflow: hidden;
				height: 100%;
				left: 0;
				top: 0;
				z-index: 4;
				min-height: 100vh;
				.banner{
					align-content: space-around;
					flex-wrap: wrap;
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					background-color: #000;
					.pic{
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
							display: block;
							transition: all 3s ease;
						}
					}
					.ind_hint_wrap{
						position: absolute;
						bottom: 0;
						left: 0;
						padding: 30px 0;
						width: 100%;
						.ind_hint{
							color: #FFFFFF;
						}
						.ind_hint_icon{
							position: relative;
							width: 16px;
							height: 26px;
							border: 2px solid #fff;
							border-radius: 8px;
							padding: 4px 0;
							margin-bottom: 10px;
							div{
								height: 6px;
								width: 2px;
								background-color: #fff;
								border-radius: 2px;
								position: absolute;
								top: 6px;
								left: 50%;
								margin-left: -1px;
								animation: gun0 1s ease infinite;
							}
						}
					}
					.topic {
						position: relative;
						z-index: 10;
						width: 100%;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						.txt_ {
							height: 130px;
							min-width: 50vw;
							margin-bottom: 2vh;
							h1 {
								color: #fff;
								line-height: 60px;
								letter-spacing: 4px;
								span > em {
									font-weight: 400;
								}
							}
							small {
								display: block;
								color: #fff;
								line-height: 40px;
								height: 40px;
								letter-spacing: 1px;
								span {
									margin: 0 4px;
									font-size: inherit;
									color: inherit;
									font-weight: inherit;
									font-family: inherit;
									line-height: 1.2;
									overflow: hidden;
									vertical-align: middle;
								}
							}
						}
					}
				}
			}
			#main{
				padding-top: 100vh;
				z-index: 100;
				pointer-events: none;
				width: 100%;
				min-height: 200vh;
				position: relative;
				overflow: hidden;
				.main-wrap{
					width: 100%;
					min-height: 70vh;
					background: #111111;
					overflow: hidden;
					z-index: 10;
					.classify-wrap{
						position: relative;
						height: 165px;
						pointer-events: auto;
						p{
							color: #8F8F8F;
							visibility: hidden;
							animation-delay: .5s;
							span{
								padding: 0 10px;
								margin:0 30px;
								cursor: pointer;
								transition: all .3s ease;
								&.active{
									color: #FFFFFF;
								}
								&:hover{
									color: #FFFFFF;
								}
							}
						}
					}
					.news-wrap{
						width: 72%;
						margin: 0 auto;
						justify-content: flex-start;
						align-items: flex-start;
						flex-wrap: wrap;
						pointer-events: auto;
						.news-item{
							width: calc((100% - 116px) / 3);
							margin-right: 58px;
							margin-top: 20px;
							padding-bottom: 20px;
							visibility: hidden;
							&:hover{
								.img{
									transform: scale(1.08);
								}
							}
							&:nth-child(3n){
								margin-right: 0;
							}
							.news-img{
								width: 100%;
								max-height: 15em;
								min-height: 15em;
								overflow: hidden;
								.img{
									width: 100%;
									display: block;
									object-fit: cover;
									transition: all 0.36s ease;
								}
							}
							.news-content{
								width: 100%;
								.title{
									width: 100%;
									margin: 20px 0;
									color: #FFFFFF;
								}
								.detail-txt,.news-time{
									width: 100%;
									color: #8F8F8F;
								}
								.detail-txt{
									line-height: 1.5em;
									height: 3em;
									max-height: 3em;
									overflow: hidden;
									margin-bottom: 40px;
								}
							}
						}
					}
					.empty-wrap{
						min-height: 70vh;
					}
				}
			}
		}
	}
</style>
